import { useLoadingStore } from '@store/loading.store';

type MainLoadingSpinnerProps = {
    width?: number;
    height?: number;
    duration?: string;
    stroke?: number;
};

function MainLoadingSpinner({ width = 90, height = 90, duration = '2s', stroke = 15 }: MainLoadingSpinnerProps) {
    const { isLoading, changeLoading, isCancel, changeLoadingAndCancel, callback } = useLoadingStore((state) => state);

    return isLoading ? (
        <div className={`spinner-container ${isCancel ? 'spinner-column' : ''}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                width={width}
                height={height}
            >
                <g>
                    <defs>
                        <clipPath id="$id-cp">
                            <path
                                d="M 65.5 50 A 4.5 4.5 0 0 1 74.5 50 A 24.5 24.5 0 1 1 50 25.5 A 4.5 4.5 0 0 1 50 34.5 A 15.5 15.5 0 1 0 65.5 50"
                                transform="matrix(-0.36813628958056005,-0.9297718388367429,0.9297718388367429,-0.36813628958056005,21.91822253719086,114.89540642086514)"
                            ></path>
                        </clipPath>
                    </defs>
                    <g clipPath="url(#$id-cp)">
                        <rect x="20.5" y="0" width={stroke} height="100" fill="rgb(248, 176, 106)"></rect>
                        <rect x="32.1" y="0" width={stroke} height="100" fill="rgb(245, 134, 98)"></rect>
                        <rect x="43.7" y="0" width={stroke} height="100" fill="rgb(232, 104, 99)"></rect>
                        <rect x="55.3" y="0" width={stroke} height="100" fill="rgb(149, 170, 132)"></rect>
                        <rect x="66.9" y="0" width={stroke} height="100" fill="rgb(189, 186, 123)"></rect>
                    </g>
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 50 50"
                        to="360 50 50"
                        dur={duration}
                        repeatCount="indefinite"
                    />
                </g>
            </svg>
            {isCancel && (
                <div>
                    <button
                        className="loading_btn red"
                        onClick={() => {
                            if (callback) {
                                changeLoadingAndCancel(false, false);
                                callback();
                            }
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
}

export default MainLoadingSpinner;
