import { json, LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
    Links,
    LiveReload,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useRouteError,
} from '@remix-run/react';
import qullSnow from 'quill/dist/quill.snow.css?url';
import { ReactNode } from 'react';
import reactDatepicker from 'react-datepicker/dist/react-datepicker.css?url';
import { ToastContainer } from 'react-toastify';
import ReactToastify from 'react-toastify/dist/ReactToastify.css?url';
import cdtpicker from '@assets/common/dtpicker.scss?url';
import mainLoading from '@assets/common/main.loading.scss?url';
import quillCustom from '@assets/common/quill.custom.scss?url';
import ModalContainer from '@components/admin/modal/ModalContainer';
import { useToast } from '@hooks/useToast';
import { useChangeLanguage } from '@server/localization/i18n';
import i18next from '@server/localization/i18n.server';
import { getToast } from '@server/toast.server';
import { combineHeaders } from '@server/utils/common';

export const links: LinksFunction = () => [
    { rel: 'stylesheet', href: reactDatepicker },
    { rel: 'stylesheet', href: cdtpicker },
    { rel: 'stylesheet', href: mainLoading },
    { rel: 'stylesheet', href: ReactToastify },
    { rel: 'stylesheet', href: qullSnow },
    { rel: 'stylesheet', href: quillCustom },
];

export const handle = {
    i18n: 'common',
};

export async function loader({ request }: LoaderFunctionArgs) {
    const { toast, headers: toastHeaders } = await getToast(request);
    const locale = await i18next.getLocale(request);

    return json(
        {
            toast,
            locale,
        },
        {
            headers: combineHeaders(toastHeaders),
        },
    );
}

function Document({ children, locale }: { children: ReactNode; locale: string }) {
    return (
        <html lang={locale}>
            <head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                />
                <Meta />
                <script src="/global.js"></script>
                <Links />
            </head>
            <body>
                {children}
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
            </body>
        </html>
    );
}

export default function App() {
    const { toast, locale } = useLoaderData<typeof loader>();
    useToast(toast);
    useChangeLanguage(locale);

    return (
        <Document locale={locale}>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Outlet />
            <ModalContainer />
        </Document>
    );
}

export function ErrorBoundary() {
    const error = useRouteError();

    console.log('error', error);

    return <div className="error_con">{'에러'}</div>;
}
