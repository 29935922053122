import { create } from 'zustand';

export type LoadingState = {
    isLoading: boolean;
    isCancel: boolean;
    callback?: () => void;
};

export type LoadingActions = {
    changeLoading: (is: boolean) => void;
    changeLoadingAndCancel: (isLoading: boolean, isCancel: boolean, callback?: () => void) => void;
};

export type LoadingStore = LoadingState & LoadingActions;

export const initLoadingStore = (): LoadingState => {
    return {
        isLoading: false,
        isCancel: false,
        callback: undefined,
    };
};

export const defaultInitState: LoadingState = {
    isLoading: false,
    isCancel: false,
    callback: undefined,
};

export const useLoadingStore = create<LoadingStore>()((set, get) => ({
    ...defaultInitState,
    changeLoading: (is) => {
        set((state) => ({ isLoading: is }));
    },
    changeLoadingAndCancel: (isLoading, isCancel, callback) => {
        set((state) => ({
            isLoading: isLoading,
            isCancel: isCancel,
            ...(callback ? { callback } : {}), // Add callback only if it exists
        }));
    },
}));
